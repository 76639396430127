/**
* @Author: MidhunMohan
* @Date:   06-Apr-2017 14:20 +05:30
* @Project: Ezyprocure
* @Filename: staff-interface.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-07-16T11:47:21+05:30
*/

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('StaffInterfaceController', StaffInterfaceController);

  StaffInterfaceController.$inject = ['$scope', '$q', '$rootScope', '$filter', '$state', '$stateParams', '$mdDialog', 'Auth', 'toastr', 'BuyerOCRService', 'UTCDateService', 'pagingParams', 'paginationConstants', 'BuyerBuyNowService', 'PurchaseOrderService', 'BuyerBusinessUserMapService', 'BuyerReceivingOrderService', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS', 'NotificationService', 'CallbackResourceService', 'XeroResourceService', 'BuyerDataService', 'InvoiceService', 'GLOBAL_CONSTANTS', 'BuyerUserManagementService', 'BuyerAdhocPOFormService'];

  function StaffInterfaceController($scope, $q, $rootScope, $filter, $state, $stateParams, $mdDialog, Auth, toastr, BuyerOCRService, UTCDateService, pagingParams, paginationConstants, BuyerBuyNowService, PurchaseOrderService, BuyerBusinessUserMapService, BuyerReceivingOrderService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONFIG_CONSTANTS, NotificationService, CallbackResourceService, XeroResourceService, BuyerDataService, InvoiceService, GLOBAL_CONSTANTS, BuyerUserManagementService,BuyerAdhocPOFormService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.ocrPredicate = pagingParams.ocrPredicate;
    vm.ocrReverse = pagingParams.ocrAscending;

    vm.init = init;
    vm.getAllPO = getAllPO;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.filter = {};
    vm.buyerProfile = BuyerDataService.getBuyerProfile();
    vm.accessControls = vm.buyerProfile.accessDTO;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.resetFields = resetFields;
    vm.saveQueryFilters = saveQueryFilters;
    vm.quickBooksCSVHeader = EXPORT_CONSTANTS.CSV.quickBooksCSVHeader;
    vm.quickBooksItemsCSVHeader = EXPORT_CONSTANTS.CSV.quickBooksItemsCSVHeader;
    vm.getPurchaseOrderIdList = getPurchaseOrderIdList;
    var todaysDate = new Date();
    vm.filter.toDate = new Date();
    todaysDate = todaysDate.setDate(todaysDate.getDate() - 30);
    vm.filter.fromDate = UTCDateService.utcToDate(new Date(todaysDate));
    vm.accessForOcrVerification = Auth.hasPermission("accessForOcrVerification");
    vm.accessForAutocountExport = Auth.hasPermission("accessForAutocountExport");
    vm.isAdminBuyer = BuyerDataService.isAdminBuyer();
    vm.hasBuyerAdminAccessForOcrVerification = vm.isAdminBuyer && vm.accessForOcrVerification;

    vm.getStaffInterfaceCSVData = getStaffInterfaceCSVData;
    vm.checkAllRows = checkAllRows;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.pickAllPOIdsFromGroupedPOItems = pickAllPOIdsFromGroupedPOItems;
    vm.exportData = exportData;
    vm.exportToAX = exportToAX;
    vm.exportToGEO = exportToGEO;
    vm.exportToQuickBooks = exportToQuickBooks;
    vm.exportToQuickBooksItems = exportToQuickBooksItems;
    vm.quickBookExportGlFilter = quickBookExportGlFilter;
    vm.updateGeoExported = updateGeoExported;
    vm.updateGeoExportedFromList = updateGeoExportedFromList;
    vm.ocrInvStatusClass = GLOBAL_CONFIG_CONSTANTS.OCR_INVOICE_STATUS.LABEL_CLASS;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.ocrVerifiedClass = GLOBAL_CONFIG_CONSTANTS.OCR_VERIFIED.LABEL_CLASS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.query = JSON.parse(localStorage.getItem("ocr_report_query")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1,
      maxLimit: paginationConstants.maxLimit
    };
    vm.filter.ocrVerified = null;
    vm.mergeOCRWithSamePO = mergeOCRWithSamePO;
    vm.exportCSV = exportCSV;

    // XERO
    vm.connectToXero = connectToXero;
    vm.disconnectToXero = disconnectToXero;
    vm.exportToXero = exportToXero;
    vm.fetchExportToXeroList = fetchExportToXeroList;
    vm.getOraganization = getOraganization;
    vm.closeModal = closeModal;
    //ACCPAC
    vm.exportToAccPac = exportToAccPac;
    vm.selectAccpacExportedPOs = selectAccpacExportedPOs;
    vm.showAccpacExportTypeConfirmationModal = showAccpacExportTypeConfirmationModal;
    vm.accPacCSVHeader = EXPORT_CONSTANTS.CSV.accPacCSVHeader;
    vm.glMappingsExist = true;
    vm.buyerExternalCompanyId = '';

    // MYOB
    vm.myobCSVHeader = EXPORT_CONSTANTS.CSV.myobCSVHeader;
    vm.myobCSVFileHeader = EXPORT_CONSTANTS.CSV.myobCSVFileHeader;
    vm.exportToMyobFile = exportToMyobFile;
    vm.exportToMyob = exportToMyob;
    vm.showMyobExportTypeConfirmationModal = showMyobExportTypeConfirmationModal;
    vm.cancel = cancel;
    vm.getSelectedUnexportedMyob = getSelectedUnexportedMyob;
    vm.updateMyobExported = updateMyobExported;
    vm.updateMyobExportedFromList = updateMyobExportedFromList;
    vm.updateXeroExportedFromList = updateXeroExportedFromList;
    vm.getJurnalCSVData = getJurnalCSVData;
    vm.getAllJurnalExportDetails = getAllJurnalExportDetails;
    vm.purchaseInvoiceJurnalHeader = EXPORT_CONSTANTS.CSV.purchaseInvoiceJurnalCSVHeader;
    vm.todaysDate = new Date();
    var dd      = vm.todaysDate.getDate();
    var mm      = vm.todaysDate.getMonth()+1;
    var yyyy    = vm.todaysDate.getFullYear();
    if(dd<10) { dd='0'+dd; }
    if(mm<10) { mm='0'+mm; }
    vm.todayAccPac = yyyy.toString()+mm.toString()+dd.toString();
    vm.todayAP = yyyy.toString()+mm.toString()+dd.toString();
    vm.updateJurnalExported = updateJurnalExported;
    vm.exportJurnal = exportJurnal;
    vm.XeroPurchaseInvoiceExportCSVHeader = EXPORT_CONSTANTS.CSV.XeroPurchaseInvoiceExportCSVHeader;
    vm.exportXeroCSV = exportXeroCSV;

    //Million Accounting Template
    vm.millionAccountingTemplateHeader = EXPORT_CONSTANTS.CSV.MillionAccountingTemplateHeader;
    vm.exportToMillionAccountingTemplate = exportToMillionAccountingTemplate;
    vm.getOcrAcceptedPO = getOcrAcceptedPO;
    vm.getOcrAcceptedInvoiceProcessedPO = getOcrAcceptedInvoiceProcessedPO;
    vm.millionExportRequestDTO = millionExportRequestDTO;
    vm.updateMillionExportedFromList = updateMillionExportedFromList;

    //MassAcceptPO
    vm.massAcceptPO = massAcceptPO;
    vm.selectForMassAcceptPOs = selectForMassAcceptPOs;

    //export to XML
    vm.getSelectedXMLExportPOs = getSelectedXMLExportPOs;
    vm.exportToXML = exportToXML;

    //export to SAGA50
    vm.exportToSAGA50 = exportToSAGA50;
    vm.selectSAGA50ExportedPOs = selectSAGA50ExportedPOs;
    vm.SAGA50CSVHeader = EXPORT_CONSTANTS.CSV.SAGA50CSVHeader;
    vm.updateSAGA50ExportedFromList = updateSAGA50ExportedFromList;
    vm._base64ToArrayBuffer = _base64ToArrayBuffer;
    vm.exportToSunSystem = exportToSunSystem;
    vm.selectSunSystemPOs = selectSunSystemPOs;
    var ocrList = {};

    //export to Net Suite
    vm.exportNetSuite = exportNetSuite;
    vm.showNetSuiteExportTypeConfirmationModal = showNetSuiteExportTypeConfirmationModal;
    vm.getSelectedUnexportedNetSuite = getSelectedUnexportedNetSuite;
    vm.selectNetSuitePOs = selectNetSuitePOs;
    vm.selectNetSuiteAcceptedPOs = selectNetSuiteAcceptedPOs;

    vm.exportToSQL = exportToSQL;
    vm.exportToAutocount=exportToAutocount;
    vm.selectAPUnExportedPOs = selectAPUnExportedPOs;
    vm.showAPExportTypeConfirmationModal = showAPExportTypeConfirmationModal;
    vm.apCSVHeader = EXPORT_CONSTANTS.CSV.apCSVHeader;

    vm.selectOdooAPUnExportedPOs = selectOdooAPUnExportedPOs;
    vm.exportToOdooAp = exportToOdooAp;
    vm.showOdooAPExportTypeConfirmationModal = showOdooAPExportTypeConfirmationModal;
    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    function ocrSort() {
      var result = [vm.ocrPredicate + ',' + (vm.ocrReverse ? 'asc' : 'desc')];
      return result;
    }


    /**
    * [init description]
    * @return {[type]} [description]
    */
    function init() {
      BuyerAdhocPOFormService.gstConfigDetails().then(function (res) {
        localStorage.setItem("gst-config", JSON.stringify(res[0].configValue));
      });
      vm.showExportAccpacModal = true;
      //retrieve staff interface filter params
      if (BuyerOCRService.getStaffInterfaceFilter()) {
        vm.filter = BuyerOCRService.getStaffInterfaceFilter();
        vm.query = vm.filter.query;
        vm.predicate = vm.filter.predicate;
        vm.reverse = vm.filter.reverse;
      }
      var ocrDetails = localStorage.getItem('ocr_report_filter');
      if(ocrDetails){
        ocrList = JSON.parse(ocrDetails);
        ocrGetSelectedItemsList();
      }
      vm.getAllPO();
      vm.getAllAllocatedSuppliersForBusiness();
      vm.getAllBuyerBusinessOutlets();
      vm.showExportMyobModal = true;
      vm.showExportNetSuiteModal = true;
      if(!vm.isAdminBuyer) {
        getAdminBuyerProfile(vm.buyerProfile.buyerUser.buyer.id);
      }
      vm.showExportAPModal = true;
      vm.showExportOdooAPModal = true;
    }

    vm.init();

    function getAdminBuyerProfile(companyId) {
      BuyerUserManagementService.getBuyerUserByCompanyId(companyId).then(function (response) {
        if (response) {
          vm.hasBuyerAdminAccessForOcrVerification = response.userAccess.accessForOcrVerification;
        }
      },
        function (error) {

        });
    }

    function ocrGetSelectedItemsList(){
      vm.filter.fromDate = ocrList.fromDate ? ocrList.fromDate : '';
      vm.filter.toDate = ocrList.fromDate ? ocrList.toDate : '';
      vm.filter.invoiceStatus = ocrList.invoiceStatus ? ocrList.invoiceStatus : '';
      vm.filter.geoExported = ocrList.geoExported ?  ocrList.geoExported : '';
      vm.filter.businessOutletIds = (!ocrList.businessOutletIds || ocrList.businessOutletIds.length < 1) ? '': ocrList.businessOutletIds;
      vm.filter.supplierIds = (!ocrList.supplierIds || ocrList.supplierIds.length < 1) ? '':ocrList.supplierIds;
      vm.filter.poNumber = ocrList.poNumber ? ocrList.poNumber : '';
      vm.filter.ocrAcceptanceStatus = ocrList.ocrAcceptanceStatus ? ocrList.ocrAcceptanceStatus : '';
      vm.filter.hardcopyInvoiceOCRResult = ocrList.hardcopyInvoiceOCRResult ? ocrList.hardcopyInvoiceOCRResult : '';
      vm.filter.eInvoiceOCRResult = ocrList.eInvoiceOCRResult ? ocrList.eInvoiceOCRResult : '';
      vm.filter.eInvoiceOCRResult = ocrList.eInvoiceOCRResult ? ocrList.eInvoiceOCRResult : '';
      vm.filter.ocrVerified = ocrList.ocrVerified ? ocrList.ocrVerified : null;
    }

    function saveQueryFilters() {
      vm.filter.query = vm.query;
      vm.filter.predicate = vm.predicate;
      vm.filter.reverse = vm.reverse;
      BuyerOCRService.setStaffInterfaceFilter(vm.filter);
    }

    /**
    * [getAllPO description]
    * @return {[type]} [description]
    */
    function getAllPO() {
      localStorage.setItem('ocr_report_filter',JSON.stringify(vm.filter));
      localStorage.setItem('ocr_report_query',JSON.stringify(vm.query));
      vm.recordError = [];
      var supplierIds= (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? '':vm.filter.supplierIds;
      var supplierNameArray=  (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? '':vm.filter.supplierNameArray;
  
  // Filter suppliers based on conditions

var filteredSuppliers = _.filter(vm.supplierList, function(supplier) {
  return supplierIds.includes(supplier.id) ;
});
      if (filteredSuppliers.length > 0) {
        vm.filter.supplierNameArray = [];
        supplierNameArray = _.map(filteredSuppliers, function (supplier) {
          vm.filter.supplierNameArray.push(supplier.companyName)
          return supplier.companyName;
        });
      }

// Filter supplierIds to remove ids present in filteredSuppliers

supplierIds = _.filter(supplierIds, function(id) {
  return !filteredSuppliers.some(function(supplier) {
    return supplier.id === id;
})
});


      vm.saveQueryFilters();
      const queryParams ={
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort:ocrSort() // Pass as a string
      };
      const reqBody={
        query: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
        invoiceStatus: !vm.filter.invoiceStatus ? null:vm.filter.invoiceStatus,
        geoExported: !vm.filter.geoExported ? '':vm.filter.geoExported,
        businessOutletIds: (!vm.filter.businessOutletIds || vm.filter.businessOutletIds.length < 1) ? null:vm.filter.businessOutletIds,
        //supplierId:supplierIds.length>0?supplierIds:'',
        paymentStatus: '',
        kycStatus: '',
       
        ocrAcceptanceStatus: !vm.filter.ocrAcceptanceStatus? '':vm.filter.ocrAcceptanceStatus,
        hardCopyInvoiceStatus:!vm.filter.hardcopyInvoiceOCRResult? '':vm.filter.hardcopyInvoiceOCRResult,
        eInvoiceStatus:!vm.filter.eInvoiceOCRResult ? '':vm.filter.eInvoiceOCRResult,
        ocrVerify: vm.filter.ocrVerified == "null" ? null : vm.filter.ocrVerified,
        adhocOffBoardSupplier: (!supplierNameArray|| supplierNameArray.length < 1) ? null:supplierNameArray
      }
      BuyerOCRService.getAllPurchaseOrders(queryParams,reqBody).then(function(response) {
        vm.poList = response.data;
        _.forEach(response.data, function(each){
            if(each.purchaseOrder.otherCharges){
              var otherCharges = JSON.parse(each.purchaseOrder.otherCharges);
              each.grnamount = each.grnamount + otherCharges[Object.keys(otherCharges)];
            }
        })
        // store external company id - for csv export filename
        setExternalCompanyId();
        vm.totalItems = response.headers('X-Total-Count');
        console.log(vm.totalItems);
        
      }, function (error) {
        NotificationService.error({
          title: 'PO Listing',
          error: error
        });
      });
    }

    function setExternalCompanyId() {
      if(vm.poList.length != 0){
     try {
      vm.buyerExternalCompanyId = vm.poList[0].purchaseOrder.grBuyerUser.buyer.externalCompanyId;
     } catch (error) {
      console.error(error)
     }
      }
    }

  /**
   * mergeGoodsRecivesWithSamePO merges goods receving data wuth same po number
   */
  function mergeOCRWithSamePO(){
    var grnList = [];
    var grnPONumberList = [];
    var grnDeliveryDateList = [];
    var selectedPOList =[];
    selectedPOList = vm.getSelectedPOs(vm.poList);
    if(selectedPOList.length >1){
    var acceptedPos = _.find(selectedPOList,['purchaseOrder.ocrAcceptanceStatus', 'ACCEPTED']);
    if(!acceptedPos){
      grnList = _.filter( selectedPOList ,function(eachRequest){
        grnPONumberList.push(eachRequest.purchaseOrder.purchaseOrderNumber);
        grnDeliveryDateList.push(eachRequest.purchaseOrder.processOrder.deliveryDate);
        return eachRequest;

    });
    grnList = _.map(grnList, function(eachRequest){
     return {
       'deliveryDate':eachRequest.purchaseOrder.processOrder.deliveryDate,
       'purchaseOrderNumber': eachRequest.purchaseOrder.purchaseOrderNumber
     };
    })
      var poNumberList =[];
      poNumberList = _.uniq(grnPONumberList, function(items){
       return items;
      });
      if(poNumberList.length == 1){
       BuyerReceivingOrderService.mergeGrnWithSamePo(grnList).
       then(function(response){
         NotificationService.success({
           title: 'alertMessage.MERGING_POS',
           message: 'alertMessage.MERGED_SUCCESSFULLY'
       });
       vm.init();
       }, function(error){
         NotificationService.error({
           title: 'alertMessage.MERGING_POS',
           error: error
         });
       });

      }
      else {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.MERGING_POS',
          message: 'alertMessage.PO_NUMBER_SHOULD_BE_SAME_FOR_ALL'
        });
      }
    }
    else{
      NotificationService.simpleErrorToast({
        title: 'alertMessage.MERGING_POS',
        message: 'alertMessage.CANNOT_MERGE_OCR_ACCEPTED_POs'
      });
    }
  }
  else{
    NotificationService.simpleErrorToast({
      title: 'alertMessage.MERGING_POS',
      message: 'alertMessage.ATLEAST_TWO_REQUIRED'
    });
 }

  }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      var params = { 'businessId': '','brandIds':'','page':'OCR'};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function (response) {
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      }, function (error) {
      });
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function (response) {
          vm.businessOutlets = response;
        }, function (error) {
        });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplierIds = _.map(vm.supplierList, _.property('id'));
          break;
        case 'OUTLETS':
          vm.filter.businessOutletIds = _.map(vm.businessOutlets, _.property('id'));
          break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplierIds = [];
          break;
        case 'OUTLETS':
          vm.filter.businessOutletIds = [];
          break;
      }
    }

    function resetFields() {
      vm.filter.poNumber = '';
      vm.filter.businessOutletName = '';
      vm.filter.fromDate = '';
      vm.filter.toDate = '';
      vm.filter.ocrInvoiceStatus = '';
      vm.filter.geoExported = '';
      vm.filter.businessOutletIds = '';
      vm.filter.supplierIds = '';
      vm.filter.ocrAcceptanceStatus = '';
      vm.filter.hardcopyInvoiceOCRResult = '';
      vm.filter.eInvoiceOCRResult = '';
      vm.filter.invoiceStatus = '';
      vm.filter.ocrVerified = "null" ;
      vm.filter.supplierNameArray = '';
      BuyerOCRService.setStaffInterfaceFilter(vm.filter);
      localStorage.removeItem("ocr_report_query");
      localStorage.removeItem("ocr_report_filter");
    }

    function checkAllRows() {
      angular.forEach(vm.poList, function (item) {
        item.selected = vm.selectAllRows;
      });
    }

    function pickAllPOIdsFromGroupedPOItems(selectedPOs, actionType) {
      var poItems = _.flatten(_.without(_.map(selectedPOs, _.property('items')), undefined, null));
      if (actionType === 'GEO') {
        poItems = _.filter(poItems, function (poItem) {
          return _.isObject(poItem.glCodeMappings);
        });
      }
      var purchaseOrderIdList = _.map(poItems, _.property('purchaseOrderId'));
      return purchaseOrderIdList;
    }

    /**
    * [updateGeoExported Mark GeoExported flag for the list of purchase orders in DB]
    * @param  {[type]} selectedPOs [description]
    * @return {[type]}             [description]
    */
    function updateGeoExported(selectedPOs) {
      var purchaseOrderIdList = vm.pickAllPOIdsFromGroupedPOItems(selectedPOs, 'GEO');
      PurchaseOrderService.updateGeoExported(purchaseOrderIdList).then(function (response) {
        vm.updateGeoExportedFromList(purchaseOrderIdList);
      }, function (error) {
        NotificationService.error({
          title: 'Updating Geo Exported Status',
          error: error
        });
      });
    }

    /**
    * [updateGeoExportedFromList Update the status from the PO List]
    * @param  {[type]} purchaseOrderIdList [List of purchase order id]
    * @return {[type]}                     [description]
    */
    function updateGeoExportedFromList(purchaseOrderIdList) {
      _.forEach(vm.poList, function (po, key) {
        if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
          vm.poList[key].purchaseOrder.geoExported = true;
        }
      });
    }

    /**
    * [updateXeroExportedFromList Update the status from the PO List]
    * @param  {[type]} purchaseOrderIdList [List of purchase order id]
    * @return {[type]}                     [description]
    */
    function updateXeroExportedFromList(purchaseOrderIdList) {
      _.forEach(vm.poList, function (po, key) {
        if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
          vm.poList[key].purchaseOrder.geoExported = undefined;
        }
      });
    }

    function getSelectedPOs(poList) {
      var selectedPOs = _.filter(poList, function (po) {
        return po.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs, actionType) {
      var purchaseOrderIdList;
      if (actionType === 'GEO') {
        var selectedPOs = _.filter(selectedPOs, function (po) {
          return _.isObject(po.purchaseOrder.processOrder.glCodeMappings);
        });
      }
      purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    /**
     * [getSelectedUnexportedMyob Filters all PO's which are not exported to MYOB from the selected PO's]
     */
    function getSelectedUnexportedMyob(selectedPOs) {
      var selectedUnexportedMyob = _.filter(selectedPOs, function (po) {
        if (po.purchaseOrder.myobExported == null) {
          return po;
        }
      });
      return selectedUnexportedMyob;
    }

    function exportData(actionType) {
      vm.scale = actionType === 'ACCPAC' ? 4 : 2;
      var deferred = $q.defer();
      var purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      if (CSVExportService.validateExport(selectedPOs, actionType)) {
        purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs, actionType);
        if (actionType === 'ACCPAC' && vm.accPacExportType === 'unexported') {
          purchaseOrderIdList = vm.getSelectedPOIds(vm.unExportedPOs, actionType);
        }
        if (actionType === 'MYOB' && vm.myobExportType === 'exportUnexported') {
          purchaseOrderIdList = vm.getSelectedPOIds(vm.selectedUnexportedMyob, actionType);
        }
      }
      /**
      * [if selection is made getExportGeoDetails for selected records]
      */
      if (purchaseOrderIdList.length > 0) {
        var ocrGeoExportDTO = {
          purchaseOrderIdList: purchaseOrderIdList,
          scale: vm.scale
        };
        BuyerOCRService.getExportGeoDetails(ocrGeoExportDTO).then(function (response) {
          if(!CSVExportService.validateExport(response.data, actionType))return;
          deferred.resolve(response.data);
        }, function (error) {
          NotificationService.error({
            title: 'Fetching ' + actionType + ' Export Details',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }

      /**
      * [if no selection is made getAllExportGeoDetails based on search query]
      */
      else if (_.isEmpty(purchaseOrderIdList) && selectedPOs.length === 0 && actionType!=='XERO_CSV') {
        if(actionType === 'ACCPAC'){
          NotificationService.simpleErrorToast({
            title: 'global.userAccessTypes.exportAccPac',
            message: 'global.pleaseSelectInvoicesToExportFirst'
          });
        return deferred.promise;
        }
        BuyerOCRService.getAllExportGeoDetails({
          page: 0,
          size: paginationConstants.maxLimit,
          query: !vm.filter.poNumber ? '' : vm.filter.poNumber,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
          ocrAcceptanceStatus: 'ACCEPTED',
          hardCopyInvoiceStatus:!vm.filter.hardcopyInvoiceOCRResult? '':vm.filter.hardcopyInvoiceOCRResult,
          eInvoiceStatus:!vm.filter.eInvoiceOCRResult ? '':vm.filter.eInvoiceOCRResult,
          invoiceStatus: !vm.filter.invoiceStatus ? '' : vm.filter.invoiceStatus,
          geoExported: !vm.filter.geoExported ? '' : vm.filter.geoExported,
          businessOutletIds: (!vm.filter.businessOutletIds || vm.filter.businessOutletIds.length < 1) ? '' : vm.filter.businessOutletIds,
          supplierId: (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? '' : vm.filter.supplierIds,
          sort: sort(),
          scale:vm.scale
        }).then(function (response) {
          deferred.resolve(response.data);
        }, function (error) {
          NotificationService.error({
            title: 'Fetching ' + actionType + ' Export Details',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else {
        return deferred.promise;
      }
    }

    /**
    * [updateMyobExported Mark MyobExported flag for the list of purchase orders in DB]
    * @param  {[type]} selectedPOs [description]
    * @return {[type]}             [description]
    */
    function updateMyobExported(selectedPOs) {
      var purchaseOrderIdList = vm.pickAllPOIdsFromGroupedPOItems(selectedPOs, 'MYOB');
      PurchaseOrderService.updateMyobExported(purchaseOrderIdList).then(function (response) {
        vm.updateMyobExportedFromList(purchaseOrderIdList);
      }, function (error) {
        NotificationService.error({
          title: 'Updating MYOB Exported Status',
          error: error
        });
      });
    }

    /**
      * [updateMyobExportedFromList Update the status from the PO List]
      * @param  {[type]} purchaseOrderIdList [List of purchase order id]
      * @return {[type]}                     [description]
      */
    function updateMyobExportedFromList(purchaseOrderIdList) {
      _.forEach(vm.poList, function (po, key) {
        if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
          vm.poList[key].purchaseOrder.myobExported = true;
        }
      });
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
      if (!vm.showExportAccpacModal ||!vm.showExportAPModal || !vm.showExportNetSuiteModal ||!vm.showExportMyobModal||!vm.showExportOdooAPModal) {
        vm.showExportAccpacModal = true;
        vm.showExportAPModal = true;
        vm.showExportNetSuiteModal = true;
        vm.showExportMyobModal = true;
        vm.showExportOdooAPModal = true;
      }
    }

    /**
     * [showMyobExportTypeConfirmationModal Shows a pop up to decide the user to export or export unexported PO's]
     * @param {Object} event
     */
    function showMyobExportTypeConfirmationModal(event) {
      vm.showExportMyobModal = false;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.myob-export-type-confirm-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }


    /**
     * [exportToMyob Get Export To MYOB]
     */
    function exportToMyob(event, myobExportType) {
      var deferred = $q.defer();
      // Type of button clicked whether to know exported all or export unexported
      vm.myobExportType = myobExportType;
      vm.exportToMyobMisc = true;
      vm.exportToMyobItems = false;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      vm.selectedUnexportedMyob = vm.getSelectedUnexportedMyob(selectedPOs)
      // vm.showExportMyobModal sets to false once inside the modal and sets to true after downloading the csv.
      if (vm.selectedUnexportedMyob.length < selectedPOs.length && vm.showExportMyobModal) {
        vm.showMyobExportTypeConfirmationModal(event);
      } else if (vm.selectedUnexportedMyob.length === selectedPOs.length || (!vm.showExportMyobModal)) {
        vm.exportData('MYOB').then(function (response) {
          if (_.isEmpty(response)) {
            toastr.error('No accepted records found', 'Export MYOB Error!');
            return deferred.promise;
          } else {
            var ocrExportToMyob = CSVExportService.getExportToMyob(response);
            vm.updateMyobExported(response);
            vm.cancel();
            vm.showExportMyobModal = true;
            vm.exportToMyobMisc = false;
            deferred.resolve(ocrExportToMyob);
          }
        }), function (error) {
          NotificationService.error({
            title: 'Export to MYOB',
            error: error
          });
          deferred.reject(error);
        }
      }

        return deferred.promise;

    }

    // export myob csv file 2
    function exportToMyobFile(event, myobExportType){
      var deferred = $q.defer();
      // Type of button clicked whether to know exported all or export unexported
      vm.myobExportType = myobExportType;
      vm.exportToMyobItems = true;
      vm.exportToMyobMisc = false;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      vm.selectedUnexportedMyob = vm.getSelectedUnexportedMyob(selectedPOs)
      // vm.showExportMyobModal sets to false once inside the modal and sets to true after downloading the csv.
      if (vm.selectedUnexportedMyob.length < selectedPOs.length && vm.showExportMyobModal) {
        vm.showMyobExportTypeConfirmationModal(event);
      } else if (vm.selectedUnexportedMyob.length === selectedPOs.length || (!vm.showExportMyobModal)) {
        vm.exportData('MYOB').then(function (response) {
          if (_.isEmpty(response)) {
            toastr.error('No accepted records found', 'Export MYOB Error!');
            return deferred.promise;
          } else {
            var ocrExportToMyob = CSVExportService.getExportToMyobFile(response);
            vm.updateMyobExported(response);
            vm.cancel();
            vm.exportToMyobItems = false;
            vm.showExportMyobModal = true;
            deferred.resolve(ocrExportToMyob);
          }
        }), function (error) {
          NotificationService.error({
            title: 'Export to MYOB',
            error: error
          });
          deferred.reject(error);
        }
      }
        return deferred.promise;
    }

    function exportToQuickBooksItems(){
        var deferred = $q.defer();
      vm.exportData('QuickBooks').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records found', 'Export QuickBooks Error!');
          return deferred.promise;
        } else {
          var ocrExportGeo = CSVExportService.getQuickBooksItemsCSV(response);
          vm.updateGeoExported(response);
          deferred.resolve(ocrExportGeo);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Export QuickBooks Items',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    //Get Export To QuickBooks
    function exportToQuickBooks() {
      var deferred = $q.defer();
      vm.exportData('QuickBooks').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records found', 'Export QuickBooks Error!');
          return deferred.promise;
        } else {
          var quickbookExportData = vm.quickBookExportGlFilter(response);
          var ocrExportGeo = CSVExportService.getBatchQuickBooksCSV(quickbookExportData, 'QUICKBOOKS', 'BUYER');
          vm.updateGeoExported(response);
          deferred.resolve(_.flatten(ocrExportGeo));
        }
      }, function (error) {
        NotificationService.error({
          title: 'Export QuickBooks',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


    //filters out items with same GL-code
    function quickBookExportGlFilter(response){
      vm.quickbookArray =[];
      var quickbookExportData = response;
      _.forEach(quickbookExportData , function(PO){
       var tempPO = angular.copy(PO);
       vm.newQuickBookPOObject = angular.copy(PO);
       vm.newQuickBookPOObject.items = [];
       //(uniqueGlItemList) po items with unique gl names
        vm.uniqueGlItemList = _.uniqBy(PO.items, 'glCodeMappings.glSpecificItemGroups.name');
        _.forEach(vm.uniqueGlItemList , function(uniqPO){
          uniqPO.poAmount = _.sumBy(tempPO.items, function(tempItem) {
            if(uniqPO.glCodeMappings.glSpecificItemGroups.name == tempItem.glCodeMappings.glSpecificItemGroups.name ){
              return tempItem.lineAmount;
            }
          });
          vm.newQuickBookPOObject.items.push(uniqPO);
        })
        vm.quickbookArray.push(vm.newQuickBookPOObject);
      })
      return vm.quickbookArray;
    }


    //Get Export To GEO
    function exportToGEO() {
      var deferred = $q.defer();
      vm.exportData('GEO').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records with GL Mappings found', 'Export GEO Error!');
          return deferred.promise;
        } else {
          var ocrExportGeo = CSVExportService.getBatchGEOInvoiceCSV(response);
          vm.updateGeoExported(response);
          deferred.resolve(ocrExportGeo);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Export GEO',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    //Get Export To AX
    function exportToAX() {
      var deferred = $q.defer();
      vm.exportData('AX').then(function (exportedResponseData) {
        var purchaseOrderIdList = vm.pickAllPOIdsFromGroupedPOItems(exportedResponseData, 'AX');
        vm.updateGeoExported(exportedResponseData);
        BuyerOCRService.webserviceinvoice(purchaseOrderIdList,{scale:vm.scale}).then(function (response) {
          NotificationService.success({
            title: 'Web service',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Could not connect Web service',
            error: error
          });
        });
      }, function (error) {
        NotificationService.error({
          title: 'Export AX',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function connectToXero() {
      CallbackResourceService.redirectUrl({
        module: 2,
      }).then(function (response) {
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function disconnectToXero() {
      vm.organisationName="";
      CallbackResourceService.disconnectToXero().then(function (response) {
        vm.xero = response.xero;
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });

      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.disconnectXERO',
          error: error
        });
      });

    }

    function fetchExportToXeroList() {
      var purchaseOrderIdList = [];
      vm.selectedPOs = vm.getSelectedPOs(vm.poList);
      var purchaseOrderIdList = [];
      vm.unExportForXeroIdList = [];
      _.filter(vm.selectedPOs, function (poForXero) {
        if (poForXero.purchaseOrder.ocrAcceptanceStatus === "ACCEPTED") {
          purchaseOrderIdList.push(poForXero.purchaseOrder.id);
        } else {
          vm.unExportForXeroIdList.push(poForXero.purchaseOrder.id);
        }
      });
      return purchaseOrderIdList;
    }

    function exportToXero() {
      var purchaseOrderIdList = fetchExportToXeroList();
      if (vm.selectedPOs.length > 0) {
        if (vm.unExportForXeroIdList.length <= 0) {
          XeroResourceService.exportInvoiceToXero({
            sort: sort(),
            query: !vm.filter.poNumber ? '' : vm.filter.poNumber,
            fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
            toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
            ocrAcceptanceStatus: 'ACCEPTED',
            hardCopyInvoiceStatus:!vm.filter.hardcopyInvoiceOCRResult? '':vm.filter.hardcopyInvoiceOCRResult,
            eInvoiceStatus:!vm.filter.eInvoiceOCRResult ? '':vm.filter.eInvoiceOCRResult,
            invoiceStatus: !vm.filter.invoiceStatus ? '' : vm.filter.invoiceStatus,
            geoExported: !vm.filter.geoExported ? '' : vm.filter.geoExported,
            businessOutletIds: (!vm.filter.businessOutletIds || vm.filter.businessOutletIds.length < 1) ? '' : vm.filter.businessOutletIds,
            supplierId: (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? '' : vm.filter.supplierIds,
            purchaseOrderIdList: (purchaseOrderIdList.length === 0) ? [''] : purchaseOrderIdList,
            module: 2,
            scale:vm.scale
          }).then(function (response) {
            vm.message = response.headers('X-ezyprocureApp-alert')
            NotificationService.success({
              title: 'global.buttons.exportXERO',
              message: vm.message
            });
            setTimeout(function () {
              vm.init();
            }, 60000);
            vm.updateXeroExportedFromList(purchaseOrderIdList);
            vm.selectAllRows = false;
          }, function (error) {
            NotificationService.error({
              title: 'global.buttons.exportXERO',
              error: error
            });
            getTokenExpiry();
            disableConnectToXeroBtn();
          });
        } else {
          NotificationService.simpleErrorToast({
            title: 'global.buttons.exportXERO',
            message: 'alertMessage.ACCEPTED_INVOICES'
          });
        }
      } else {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.exportXERO',
          message: 'alertMessage.SEARCH_AND_SELECT'
        });
      }
    }

    function getTokenExpiry() {

      CallbackResourceService.getTokenExpiry(
      ).then(function (response) {
        vm.xero = response;
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });

    }

    function disableConnectToXeroBtn() {

      CallbackResourceService.getAuthTimeout(
      ).then(function (response) {
        vm.xero = response;
        vm.organisationName = vm.xero.errors;
        if(window.location.href.indexOf("?") > -1) {
          var code = window.location.href.split('code=')[1].split('&scope=')[0];
          var scope = window.location.href.split('scope=')[1].split('&session_state=')[0];
          var session_state = window.location.href.split('session_state=')[1].split('&state')[0];
          var state = window.location.href.split('state=')[1];
          CallbackResourceService.xeroCallback({ code: code, scope: scope, sessionState: session_state,state: state })
          .then(function(response){
            vm.xero = response;
            vm.organisationdata = response.data.data;
            if(vm.organisationdata.length == 1){
              vm.tenantId = vm.organisationdata[0].tenantId;
              vm.getOraganization();
            } else {
              $mdDialog.show({
                templateUrl: 'app/core/common-templates/xero-multiple-organization.html',
                controller: function() {
                  return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: true
              });
            }
            if(!vm.organisationName){
            vm.organisationName = vm.xero.errors;
            }
           code = null;
           scope = null;
           session_state=null;
           state=null;
          }, function(error){
            vm.xero = CallbackResourceService.getXeroConnectionParams();
            NotificationService.error({
              title: 'global.buttons.connectXERO',
              error: error
            });
          });
        }
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function getOraganization(){
      closeModal();
      var params ={
        tenantId: vm.tenantId
      };
      CallbackResourceService.xeroConnect(params).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.data.responseList[0].reason;
        NotificationService.success({
          title: 'global.buttons.connectXERO',
          message: 'alertMessage.XERO.SUCCESS'
        });
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
     }

    function closeModal() {
      $mdDialog.cancel();
    }

    /**
     * getPurchaseOrderIdList filters out the selected POs and returns a list of poIds
     */
    function getPurchaseOrderIdList() {

      var purchaseOrderIdList = [];
      _.filter(vm.poList, function (poId) {
        if (poId.selected == true) {
          purchaseOrderIdList.push(poId.purchaseOrder.id);
        }
      });
      vm.purchaseOrderIdList = purchaseOrderIdList;
      return vm.purchaseOrderIdList;
    }

    disableConnectToXeroBtn();

    function exportCSV() {
        $mdDialog.show({
          templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.csv.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
    }

    //Get Export To CS
    function getStaffInterfaceCSVData(size) {
      var deferred = $q.defer();
      vm.staffInterfaceHeader = EXPORT_CONSTANTS.CSV.staffInterfaceHeader;
      vm.purchaseOrderIdList = vm.getPurchaseOrderIdList();
      BuyerOCRService.getAllExportCSVDetails({
        page: 0,
        size: size,
        query: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        sort: ["poNumber,asc"],
        scale: vm.scale
      }, {
          brandIds: [],
          fromDate: !vm.filter.fromDate ? null : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
          toDate: !vm.filter.toDate ? null : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
          ocrAcceptanceStatus: !vm.filter.ocrAcceptanceStatus ? null : vm.filter.ocrAcceptanceStatus,
          hardCopyInvoiceStatus:!vm.filter.hardcopyInvoiceOCRResult? null:vm.filter.hardcopyInvoiceOCRResult,
          eInvoiceStatus:!vm.filter.eInvoiceOCRResult ? null:vm.filter.eInvoiceOCRResult,
          invoiceStatus: !vm.filter.invoiceStatus ? null : vm.filter.invoiceStatus,
          geoExported: !vm.filter.geoExported ? null : vm.filter.geoExported,
          businessOutletIds: (!vm.filter.businessOutletIds || vm.filter.businessOutletIds.length < 1) ? [] : vm.filter.businessOutletIds,
          supplierId: (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? [] : vm.filter.supplierIds,
          paymentStatus: null,
          kycStatus: null,
          purchaseOrderIdList: !vm.purchaseOrderIdList ? [] : vm.purchaseOrderIdList,
        }).then(function (response) {
          if (_.isEmpty(response.data)) {
            toastr.error('No records found', 'Export CSV Error!');
            return deferred.promise;
          } else {
            var ocrExportCSV = CSVExportService.getStaffInterfaceCSV(response.data);
            deferred.resolve(ocrExportCSV);
          }
        }, function (error) {
          NotificationService.error({
            title: 'Export CSV',
            error: error
          });
          deferred.reject(error);
        });
        $mdDialog.cancel();
      return deferred.promise;
    }

    /**
     * [showAccpacExportTypeConfirmationModal opens modal to export all selected POs or export unexported POs]
     * @param {Object} event
     */
    function showAccpacExportTypeConfirmationModal(event) {
      vm.showExportAccpacModal = false;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.accpac-export-type-confirm-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    //selectAccpacExportedPOs filters the POs with accPacExpored flag false
    function selectAccpacExportedPOs(selectedPOs) {
      var exportedPOs = _.filter(selectedPOs, function (po) {
        return po.purchaseOrder.accPacExported === false;
      });
      return exportedPOs;
    }
    //Get Export To Accpac
    function exportToAccPac(event, unExported) {
      var deferred = $q.defer();
      vm.accPacExportType = unExported;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      //unExportedPOs contains the POs which is not Accpac exported
      vm.unExportedPOs = vm.selectAccpacExportedPOs(selectedPOs);
      if (vm.unExportedPOs.length < selectedPOs.length && vm.showExportAccpacModal) {
        vm.showAccpacExportTypeConfirmationModal(event);
      } else if (vm.unExportedPOs.length === selectedPOs.length || (!vm.showExportAccpacModal)) {
        var purchaseOrderIdList =
        _.map(selectedPOs, function(item){
          return {'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
           'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber}
      });
        BuyerOCRService.exportToAccpac(purchaseOrderIdList).then(function (response) {
          if (_.isEmpty(response.data)) {
            NotificationService.simpleErrorToast({
              title: 'global.userAccessTypes.exportAccPac',
              message: 'global.alertMessage.NO_ACCEPTED_RECORDS_FOUND'
            });
            return deferred.promise;
          }else  {
             var ocrExportAccpac = CSVExportService.getAccPacCSV(response.data);
              vm.cancel();
              deferred.resolve(ocrExportAccpac);
          }
        }, function (error) {
          NotificationService.error({
            title: 'Export AccPac',
            error: error
          });
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
    * [getJurnalCSVData get jurnal CSV data]
    * @return {[type]} [description]
    */
    function getJurnalCSVData() {
      var deferred = $q.defer();
      vm.getAllJurnalExportDetails().then(function (response) {
        if (_.isEmpty(response)) {
          NotificationService.simpleErrorToast({
            title: 'global.userAccessTypes.exportToJurnal',
            message: 'global.table.NO_RECORDS_FOUND'
          });
          return deferred.promise;
        } else {
          var exportPO = CSVExportService.getPurchaseInvoicesJurnalCSV(response);
          deferred.resolve(exportPO);
        }
      }, function (error) {
        NotificationService.simpleErrorToast({
          title: 'global.userAccessTypes.exportToJurnal',
          message: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


    /**
    * [updateJurnalExported Mark jurnal exported flag for the list of purchase orders in DB]
    * @param  {[type]} exportedPOs [description]
    * @return {[type]}             [description]
    */
    function updateJurnalExported(exportedPOs) {
      var poIdList = InvoiceService.getExportedPOIds(exportedPOs);
      InvoiceService.updateExportedPOStatus({
        invoiceExportTypes: "PURCHASE_JURNAL"
      }, poIdList).then(function (response) {
        vm.poList = InvoiceService.updateReportExportedFromList(vm.poList, poIdList, 'PURCHASE', 'JURNAL');
      }, function (error) {
        NotificationService.error({
          title: 'Updating Jurnal Exported Status',
          error: error
        });
      });
    }

    /**
    * [exportJurnal export PO to Jurnal]
    * @return {[type]} [description]
    */
    function exportJurnal() {
      var deferred = $q.defer();
      vm.getAllJurnalExportDetails('Jurnal').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No records found', 'Export Jurnal Error!');
          return deferred.promise;
        } else {
          var ocrExportJurnal = CSVExportService.getPurchaseInvoicesJurnalCSV(response);
          vm.updateJurnalExported(response);
          deferred.resolve(ocrExportJurnal);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Export Jurnal',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


    /**
    * [getAllJurnalExportDetails get jurnal csv details for selected POs]
    * @return {[type]} [description]
    */
    function getAllJurnalExportDetails(actionType) {
      var deferred = $q.defer();
      vm.purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      if (CSVExportService.validateExport(selectedPOs, actionType)) {
        vm.purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs, actionType);
      }

      /**
      * [if selection is made getExportGeoDetails for selected records]
      */
      PurchaseOrderService.getPOJurnalExportListing({
        jurnalExportType: 'PURCHASE',
        scale: vm.scale
      }, vm.purchaseOrderIdList).then(function (response) {
        deferred.resolve(response.data);
      }, function (error) {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingCSVExportDetails',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
     * [Export CSV Data for XERO]
     */
    function exportXeroCSV() {

       var purchaseOrderIdList = fetchExportToXeroList();
       var selectedPOs = vm.getSelectedPOs(vm.poList);
       var deferred = $q.defer();
      if (_.isEmpty(selectedPOs)) {
        NotificationService.simpleErrorToast({
          title: 'global.exportXEROCSV',
          message: 'entity.validation.mdRequireMatch'
        });
        return deferred.promise;
      } else {
      vm.exportData('XERO_CSV').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records found', 'Export XERO Error!');
          return deferred.promise;
        } else {
          getXeroCSVDTOForFlagUpdate(response);
          var ocrExportGeo = CSVExportService.getBatchQuickBooksCSV(response, 'XERO_CSV', 'BUYER');
          vm.updateGeoExportedFromList(purchaseOrderIdList);
          BuyerOCRService.updateXeroCSVExported(vm.xeroCSVDTO);
          deferred.resolve(_.flatten(ocrExportGeo));
        }
       }, function (error) {
        NotificationService.error({
          title: 'Export XERO CSV',
          error: error
        });
        deferred.reject(error);
      });
    }
      return deferred.promise;
    }
    /**
     * [ manual xeroCSVExport filter deliveryDate and purchaseOrderNumber]
     */
   function getXeroCSVDTOForFlagUpdate(response){
      vm.xeroCSVDTO = [];
      _.filter(response, function(po){
        vm.xeroCSVDTO.push({
          'deliveryDate': po.items[0].deliveryDate,
          'purchaseOrderNumber': po.purchaseOrderNumber
        })
      });
    }

    function isOcrVerified(selectedPOs) {
      var isVerified = true;
      selectedPOs.forEach(function(item) {
        if(isVerified) {
          isVerified = item.purchaseOrder.ocrVerified;
        }
      });
      return isVerified;
    }

    /**
     *massAcceptPO selects the poId of each po and accepts the PO
     */
    function massAcceptPO(){
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      if (selectedPOs.length > 0) {
        if (vm.hasBuyerAdminAccessForOcrVerification && !isOcrVerified(selectedPOs)) {
          toastr.error('OCR not verified for selected PO/s', 'OCR Verification');
          return;
        }
        var ocrInvoiceDTOList = vm.selectForMassAcceptPOs(selectedPOs);
        BuyerOCRService.massAcceptPO(ocrInvoiceDTOList)
          .then(function(response){
           NotificationService.success({
             title: 'global.massAccept',
             message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
           });
           vm.recordError = [];
            vm.getAllPO();
          },function(error){
           vm.recordError = error.data;
            if(vm.recordError.responseList[0].reason === "alertMessage.FOR_ADHOC_SUPPLIER_ORDERS_INVOICES_WITHOUT_HARDCOPY_INVOICE_NUMBER_AND_A_MATCHING_HARDCOPY_OCR_RESULT_ARE_NOT_ALLOWED_FOR_MASS_ACCEPT"){
              NotificationService.simpleErrorToast({
                title: 'global.massAccept',
                message: 'error.FOR_ADHOC_SUPPLIER_ORDERS_INVOICES_WITHOUT_HARDCOPY_INVOICE_NUMBER_AND_A_MATCHING_HARDCOPY_OCR_RESULT_ARE_NOT_ALLOWED_FOR_MASS_ACCEPT'
             });
            }
            else{
              NotificationService.simpleErrorToast({
                title: 'global.massAccept',
                message: 'error.FOR_ALLOCATED_SUPPLIER_ORDERS_INVOICES_WITHOUT_HARDCOPY_INVOICE_NUMBER_AND_A_MATCHING_HARDCOPY_OCR_RESULT_OR_MATCHING_E_INVOICE_RESULT_ARE_NOT_ALLOWED_FOR_MASS_ACCEPT'
             });
            }
        });
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'global.massAccept',
         message: 'global.pleaseSelectedOneFromTheList'
        });
      }
    }

    //selectForMassAcceptPOs filters the POId of each selected Po
    function selectForMassAcceptPOs(selectedPOs) {
      var massAcceptPoList = [];
     _.map(selectedPOs, function(item){ massAcceptPoList.push({
       'poNumber' : item.purchaseOrder.purchaseOrderNumber,
       'deliveryDate' : BuyerOCRService.toOCRDateString(item.purchaseOrder.processOrder.deliveryDate)
        })
      })
    return massAcceptPoList;
    }

   //getSelectedXMLExportPOs filters the POId and delivery date for each selected Po
    function getSelectedXMLExportPOs(selectedPOs) {
      var massExportPoList = [];
      _.map(selectedPOs, function(item){ massExportPoList.push({
          'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
          'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber,
        })
      })
      return massExportPoList;
    }

    /**
    * [updateMillionExportedFromList Update the status from the PO List]
    * @param  {[type]} purchaseOrderIdList [List of purchase order id]
    */
   function updateMillionExportedFromList(purchaseOrderIdList) {
    _.forEach(vm.poList, function (po, key) {
      if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
        vm.poList[key].millionExported = true;
      }
    });
  }

   /**
    *  exportToMillionAccountingTemplate exports po for million order template
    */
    function exportToMillionAccountingTemplate(){
      var deferred = $q.defer();
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      var ocrAcceptedPO = vm.getOcrAcceptedInvoiceProcessedPO(selectedPOs);
      var purchaseOrderIdList = vm.millionExportRequestDTO(ocrAcceptedPO);
        if(selectedPOs.length>0){
          if(ocrAcceptedPO.length != selectedPOs.length){
            NotificationService.simpleErrorToast({
              title: 'global.userAccessTypes.millionExport',
              message: 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND'
            });
          }else{
          BuyerOCRService.exportToMillionOrder(purchaseOrderIdList)
            .then(function(response){
              var exportMillionOrderTemplate = CSVExportService.getMillionOrderTemplateCSVData(response.data);
              deferred.resolve(exportMillionOrderTemplate);
              NotificationService.success({
                title: 'global.userAccessTypes.millionExport',
                message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
              });
              var selectedPOIds = vm.getSelectedPOIds(ocrAcceptedPO);
              vm.updateMillionExportedFromList(selectedPOIds);
              },function(error){
               NotificationService.error({
                 title: 'global.userAccessTypes.millionExport',
                 error: error
               });
              deferred.reject(error);
            });
          }
        }else{
          NotificationService.simpleErrorToast({
            title: 'global.userAccessTypes.millionExport',
            message: 'global.pleaseSelectedOneFromTheList'
        });
      }
     return deferred.promise;
    }

    //export invoice to xml
    function exportToXML(){
      vm.recordError = [];
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      vm.ocrInvoiceDTOList = vm.getSelectedXMLExportPOs(selectedPOs);
      if(selectedPOs.length>0){
       if (CSVExportService.validateExport(selectedPOs, 'XML')){
          BuyerOCRService.getXMLData(vm.ocrInvoiceDTOList)
           .then(function(response){
           vm.exportXMLData = response.data;
           if(vm.exportXMLData.responseList.length > 0) {
            vm.recordError = response.data;
            {
            NotificationService.simpleErrorToast({
              title: 'global.exportXML',
              message:  'error.COMPLETED_WITH_ERRORS'
              });
            }
          }
          _.forEach(vm.exportXMLData.urls,function(invoices){
              // var link = document.createElement("a");
              // document.body.appendChild(link);
              // link.target = '_blank';
              // link.href = invoices.url;
              // link.click();

              var blob = new Blob([vm._base64ToArrayBuffer(invoices.responseByteArray)], {
                type: 'application/xml'
              });
              const link = document.createElement("a");
              document.body.appendChild(link);
              link.href = URL.createObjectURL(blob);
              var dateTime = new Date().toJSON("yyyy/MM/dd HH:mm");
              link.setAttribute('download', 'oci-export'+dateTime+'.xml');
              link.click();
            });
          },function(error){
          });
        }
      }
      else{
       NotificationService.simpleErrorToast({
       title: 'global.exportXML',
       message: 'entity.validation.mdRequireMatch'
       });
      }
    }

    function _base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

     /**
    * [updateSAGA50ExportedFromList Update the status from the PO List]
    * @param  {[type]} purchaseOrderIdList [List of purchase order id]
    */
    function updateSAGA50ExportedFromList(purchaseOrderIdList) {
    _.forEach(vm.poList, function (po, key) {
    if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
        vm.poList[key].millionExported = true;
    }
    });
    }

     //Get Export To Accpac
        function exportToSAGA50(){
            var deferred = $q.defer();
            var selectedPOs = vm.getSelectedPOs(vm.poList);
            var ocrAcceptedPO = vm.getOcrAcceptedPO(selectedPOs);
            var purchaseOrderIdList = vm.selectSAGA50ExportedPOs(ocrAcceptedPO);
              if(selectedPOs.length>0){
                if(ocrAcceptedPO.length != selectedPOs.length){
                  NotificationService.simpleErrorToast({
                    title: 'global.userAccessTypes.exportSAGA50',
                    message: 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND'
                  });
                }else{
                BuyerOCRService.exportToSAGA50(purchaseOrderIdList)
                  .then(function(response){
                    var exportSAGA50Template = CSVExportService.getSAGA50CSV(response.data);
                    deferred.resolve(exportSAGA50Template);
                    NotificationService.success({
                      title: 'global.userAccessTypes.exportSAGA50',
                      message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
                    });
                    var selectedPOIds = vm.getSelectedPOIds(ocrAcceptedPO);
                    vm.updateSAGA50ExportedFromList(selectedPOIds);
                    },function(error){
                     NotificationService.error({
                       title: 'global.userAccessTypes.exportSAGA50',
                       error: error
                     });
                    deferred.reject(error);
                  });
                }
              }else{
                NotificationService.simpleErrorToast({
                  title: 'global.userAccessTypes.exportSAGA50',
                  message: 'global.pleaseSelectedOneFromTheList'
              });
            }
           return deferred.promise;
          }

           //Get Export To exportToSunSystem
        function exportToSunSystem(event, type){
            var deferred = $q.defer();
            var selectedPOs = vm.getSelectedPOs(vm.poList);
            var purchaseOrderIdList = vm.selectSunSystemPOs(selectedPOs);
            if(selectedPOs.length<0){
                NotificationService.simpleErrorToast({
                    title: 'global.userAccessTypes.exportSAGA50',
                    message: 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND'
                  });
              }else{
                BuyerOCRService.exportToSunSystem(purchaseOrderIdList, type)
                .then(function(response){
                    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.href = response.data.urls[0].url;
                    link.download = 'SunSystemExport';
                    link.click();
                  NotificationService.success({
                    title: 'global.userAccessTypes.exportToSunSystem',
                    message: 'alertMessage.PROCESSED_SUCCESSFULLY'
                  });
                  },function(error){
                   NotificationService.error({
                     title: 'global.userAccessTypes.exportToSunSystem',
                     error: error
                   });
                  deferred.reject(error);
                });
            }
           return deferred.promise;
          }

    /**
    * filters out po that is ocr accepted and invoice processed
    * @param {object} selectedPOs
    */
    function getOcrAcceptedInvoiceProcessedPO(selectedPOs){
      var ocrAcceptedPOs = _.filter(selectedPOs, function (po) {
        if (po.purchaseOrder.ocrAcceptanceStatus == 'ACCEPTED' && po.purchaseOrder.invoiceStatus == 'PROCESSED') {
          return po;
        }
      });
      return ocrAcceptedPOs;
    }

    /**
    * filters out po that is ocr accepted
    * @param {object} selectedPOs
    */
    function getOcrAcceptedPO(selectedPOs){
      var ocrAcceptedPOs = _.filter(selectedPOs, function (po) {
        if (po.purchaseOrder.ocrAcceptanceStatus == 'ACCEPTED') {
          return po;
        }
      });
      return ocrAcceptedPOs;
    }

    /**
    * creates object with deliveryDate and purchaseOrderNumber for million export
    * @param {object} selectedPOs
    */
    function millionExportRequestDTO(selectedPOs){
      var purchaseOrderIdList = [];
      _.map(selectedPOs, function(item){ purchaseOrderIdList.push({
         'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
         'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber,
      })
    })
    return purchaseOrderIdList;
  }

    /**
    * creates object with deliveryDate and purchaseOrderNumber for ExportSAGA50 export
    * @param {object} selectedPOs
    */
   function selectSAGA50ExportedPOs(selectedPOs){
    var purchaseOrderIdList = [];
    _.map(selectedPOs, function(item){ purchaseOrderIdList.push({
       'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
       'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber,
    })
  })
  return purchaseOrderIdList;
  }
  /**
  * creates object with deliveryDate and purchaseOrderNumber for sun system export
  * @param {object} selectedPOs
  */
  function selectSunSystemPOs(selectedPOs) {
    var purchaseOrderIdList = [];
    _.map(selectedPOs, function (item) {
      purchaseOrderIdList.push({
        'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
        'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber,
      })
    })
    return purchaseOrderIdList;
  }
  /**
  * creates object with deliveryDate and purchaseOrderNumber for net suite export
  * @param {object} selectedPOs
  */
  function selectNetSuitePOs(selectedPOs) {
    var purchaseOrderIdList = [];
    _.map(selectedPOs, function (item) {
      purchaseOrderIdList.push({
        'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
        'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber,
      })
    })
    return purchaseOrderIdList;
  }
  /**
  * export selectedPOs net suite csv
  */
  function exportNetSuite(event, type) {
    var deferred = $q.defer();
    var selectedPOs = vm.getSelectedPOs(vm.poList);
    if (_.isEmpty(selectedPOs)) {
      NotificationService.simpleErrorToast({
        title: 'global.exportXEROCSV',
        message: 'entity.validation.mdRequireMatch'
      });
      return;
    }
    var purchaseOrderIdList = vm.selectNetSuitePOs(selectedPOs);
    vm.selectedUnexportedNetSuite = vm.getSelectedUnexportedNetSuite(selectedPOs);
    if(type === 'unexported'){
      purchaseOrderIdList = vm.selectNetSuitePOs(vm.selectedUnexportedNetSuite);
    }
    if (selectedPOs.length < 0) {
      NotificationService.simpleErrorToast({
        title: 'global.userAccessTypes.exportToNetSuite',
        message: 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND'
      });
    } else if (vm.selectedUnexportedNetSuite.length < selectedPOs.length && vm.showExportNetSuiteModal) {
      vm.showNetSuiteExportTypeConfirmationModal(event);
    } else {
      vm.exportData('NetSuite').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records found', 'Export NetSuite Error!');
          return deferred.promise;
        } else {
          if(!purchaseOrderIdList.length){
            purchaseOrderIdList = vm.selectNetSuiteAcceptedPOs(response);
          }
          BuyerOCRService.exportToNetSuite(purchaseOrderIdList, type)
          .then(function (response) {
            if(response.data.s3Url === null){
              toastr.error(response.data.responseMessageDTOList[0].message);
              return
            }
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.href = response.data.s3Url;
            link.download = 'NetSuiteExport';
            link.click();
            vm.cancel();
            NotificationService.success({
              title: 'global.userAccessTypes.exportToNetSuite',
              message: 'alertMessage.PROCESSED_SUCCESSFULLY'
            });
          }, function (error) {
            NotificationService.error({
              title: 'global.userAccessTypes.exportToNetSuite',
              error: error
            });
            deferred.reject(error);
          });
        }
      }), function (error) {
        NotificationService.error({
          title: 'Export to NetSuite',
          error: error
        });
        deferred.reject(error);
      }
    }
    return deferred.promise;
  }


/**
* [getSelectedUnexportedNetSuite Filters all PO's which are not exported to NetSuite from the selected PO's]
*/
  function getSelectedUnexportedNetSuite(selectedPOs) {
    var selectedUnexportedNetSuite = _.filter(selectedPOs, function (po) {
      if (po.netSuiteExport == null||po.netSuiteExport == false) {
        return po;
      }
    });
    return selectedUnexportedNetSuite;
  }

/**
* [showNetSuiteExportTypeConfirmationModal Shows a pop up to decide the user to export or export unexported PO's]
* @param {Object} event
*/
  function showNetSuiteExportTypeConfirmationModal(event) {
    vm.showExportNetSuiteModal = false;
    $mdDialog.show({
      templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.netsuite-export-type-confirm-modal.tmpl.html',
      controller: function () {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: false
    });
  }

  function selectNetSuiteAcceptedPOs(selectedPOs) {
    var purchaseOrderIdList = [];
    _.map(selectedPOs, function (item) {
      purchaseOrderIdList.push({
        'deliveryDate': item.ocrInvoiceDate,
        'purchaseOrderNumber': item.purchaseOrderNumber,
      })
    })
    return purchaseOrderIdList;
  }

    //Get Export To exportToSQL
    function exportToSQL(event, unExported) {
      var deferred = $q.defer();
      vm.apExportType = unExported;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      //unExportedPOs contains the POs which is not AP exported
      vm.unExportedPOs = vm.selectAPUnExportedPOs(selectedPOs);
      if (vm.unExportedPOs.length < selectedPOs.length && vm.showExportAPModal) {
        vm.showAPExportTypeConfirmationModal(event);
      } else if (vm.unExportedPOs.length === selectedPOs.length || (!vm.showExportAPModal)) {
        var purchaseOrderIdList =
        _.map(selectedPOs, function(item){
          return {'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
           'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber}
      });
      if (vm.apExportType === 'unexported') {
        var purchaseOrderIdList =
        _.map(vm.unExportedPOs, function(item){
          return {'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
           'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber}
        });
      }
        BuyerOCRService.exportToSQL(purchaseOrderIdList).then(function (response) {

          if (_.isEmpty(response.data)) {
            NotificationService.simpleErrorToast({
              title: 'global.userAccess.title.exportToSQL',
              message: 'global.alertMessage.NO_ACCEPTED_RECORDS_FOUND'
            });
            return deferred.promise;
          }else  {
             vm.sqlExcelReport = CSVExportService.getSQLCSV(response.data);
              alasql('SELECT * INTO XLSX("SQL_Export'+vm.todayAP+ '.xlsx",{headers:true}) FROM ?', [vm.sqlExcelReport]);
              var toastrDetails = {
                title: "global.userAccess.title.exportToSQL",
                message: "alertMessage.PROCESSED_SUCCESSFULLY"
              };
              vm.cancel();
              NotificationService.success(toastrDetails);
          }
        }, function (error) {
          NotificationService.error({
            title: 'global.userAccess.title.exportToSQL',
            error: error
          });
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    //selectAPExportedPOs filters the POs with apExpored flag false
    function selectAPUnExportedPOs(selectedPOs) {
      var exportedPOs = _.filter(selectedPOs, function (po) {
        return po.apExported === false || po.apExported === null;
      });
      return exportedPOs;
    }

    /**
 * [showAPExportTypeConfirmationModal opens modal to export all selected POs or export unexported POs]
 * @param {Object} event
 */
    function showAPExportTypeConfirmationModal(event) {
      vm.showExportAPModal = false;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.sql-export-type-confirm-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    //Get Export To exportToOdooAP
    function exportToOdooAp(event, unExported) {
      var deferred = $q.defer();
      vm.odooApExportType = unExported;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      if(!CSVExportService.validateExport(selectedPOs, 'Odoo AP'))return;
      //unExportedPOs contains the POs which is not AP exported
      vm.unExportedPOs = vm.selectOdooAPUnExportedPOs(selectedPOs);
      if (vm.unExportedPOs.length < selectedPOs.length && vm.showExportOdooAPModal) {
        vm.showOdooAPExportTypeConfirmationModal(event);
      } else if (vm.unExportedPOs.length === selectedPOs.length || (!vm.showExportOdooAPModal)) {
        var purchaseOrderIdList =
          _.map(selectedPOs, function (item) {
            return {
              'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
              'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber
            }
          });
        if (vm.odooApExportType === 'unexported') {
          var purchaseOrderIdList =
            _.map(vm.unExportedPOs, function (item) {
              return {
                'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
                'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber
              }
            });
        }
        BuyerOCRService.exportToOdooAp(purchaseOrderIdList).then(function (response) {
          if (response.data.csvUrl === null) {
            toastr.error(response.data.errorMessage);
            return deferred.promise;
          } else {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.href = response.data.csvUrl;
            link.download = 'OdooAPExport';
            link.click();
            vm.cancel();
            NotificationService.success({
              title: 'global.userAccess.title.exportToOdooAp',
              message: 'alertMessage.PROCESSED_SUCCESSFULLY'
            });
            deferred.resolve(vm.exportOdooApData);

          }
        }, function (error) {
          NotificationService.error({
            title: 'global.userAccess.title.exportToOdooAp',
            error: error
          });
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    //selectOdooAPExportedPOs filters the POs with odooApExpored flag false
    function selectOdooAPUnExportedPOs(selectedPOs) {
      var exportedPOs = _.filter(selectedPOs, function (po) {
        return po.odooApExported === false || po.odooApExported === null;
      });
      return exportedPOs;
    }

    /**
* [showOdooApExportTypeConfirmationModal opens modal to export all selected POs or export unexported POs]
* @param {Object} event
*/
    function showOdooAPExportTypeConfirmationModal(event) {
      vm.showExportOdooAPModal = false;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/staff-interface.odoo-ap-export-type-confirm-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }



    function exportToAutocount(event, unExported) {
      var deferred = $q.defer();
      vm.apExportType = unExported;
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      //unExportedPOs contains the POs which is not AP exported
      vm.unExportedPOs = vm.selectAPUnExportedPOs(selectedPOs);
      if (vm.unExportedPOs.length < selectedPOs.length && vm.showExportAPModal) {
        vm.showAPExportTypeConfirmationModal(event);
      } else if (vm.unExportedPOs.length === selectedPOs.length || (!vm.showExportAPModal)) {
        var purchaseOrderIdList =
        _.map(selectedPOs, function(item){
          return {'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
           'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber}
      });
      if (vm.apExportType === 'unexported') {
        var purchaseOrderIdList =
        _.map(vm.unExportedPOs, function(item){
          return {'deliveryDate' : item.purchaseOrder.processOrder.deliveryDate,
           'purchaseOrderNumber' : item.purchaseOrder.purchaseOrderNumber}
        });
      }
        BuyerOCRService.exportToAutocount(purchaseOrderIdList).then(function (response) {

          if (_.isEmpty(response.data)) {
            NotificationService.simpleErrorToast({
              title: 'global.userAccess.title.exportToAutocount',
              message: 'global.alertMessage.NO_ACCEPTED_RECORDS_FOUND'
            });
            return deferred.promise;
          }else  {
             vm.sqlExcelReport = CSVExportService.getAutoCountCSV(response.data);
              alasql('SELECT * INTO XLSX("Autocount_Export'+vm.todayAP+ '.xlsx",{headers:true}) FROM ?', [vm.sqlExcelReport]);
              var toastrDetails = {
                title: "global.userAccess.title.exportToAutocount",
                message: "alertMessage.PROCESSED_SUCCESSFULLY"
              };
              vm.cancel();
              NotificationService.success(toastrDetails);
          }
        }, function (error) {
          NotificationService.error({
            title: 'global.userAccess.title.exportToAutocount',
            error: error
          });
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

  }}
)();
