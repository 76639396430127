/**
* @Author: Ajay
* @Date:   27-Dec-2017 16:11 +05:30
* @Project: Ezyprocure
* @Filename: buyer.adhoc-po-form.item-selection.controller.js
 * @Last modified by:   lovin
 * @Last modified time: 2020-06-09T15:20:34+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerAdhocPOFormItemSelectionController', BuyerAdhocPOFormItemSelectionController);

  BuyerAdhocPOFormItemSelectionController.$inject = ['$scope', 'BuyerItemService', '$state', '$q', 'BuyerBusinessUserMapService', 'BuyerAdhocPOFormService', 'BuyerBuyNowService', 'NotificationService', 'ItemGroupService', 'UomService', 'paginationConstants', 'GLOBAL_CONSTANTS', 'Auth', 'PurchaseOrderTypeService', 'CsvUploadService', 'UtilService', '$mdDialog', 'S3_URL_CONSTANTS','$filter', 'PDFExportService', '$mdMedia','GlMappingService','toastr'];

  function BuyerAdhocPOFormItemSelectionController($scope, BuyerItemService, $state, $q, BuyerBusinessUserMapService, BuyerAdhocPOFormService, BuyerBuyNowService, NotificationService, ItemGroupService, UomService, paginationConstants, GLOBAL_CONSTANTS, Auth, PurchaseOrderTypeService, CsvUploadService, UtilService, $mdDialog, S3_URL_CONSTANTS, $filter, PDFExportService, $mdMedia,GlMappingService,toastr) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.haveAccessForGLModule = Auth.hasPermission('accessForGlModule');
    vm.haveAccessForWhatsappPo = Auth.hasPermission('accessForWhatsappPo');
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadUoms = loadUoms;
    vm.checkIsTaxActive = checkIsTaxActive;
    vm.freeItemCheckBoxChanged = freeItemCheckBoxChanged;
    vm.updateItemPrice = updateItemPrice;
    vm.updateTotalPrice = updateTotalPrice;
    vm.calculateGST = calculateGST;
    vm.addNewAdhocInvoiceRow = addNewAdhocInvoiceRow;
    vm.deleteAdhocInvoiceRow = deleteAdhocInvoiceRow;
    vm.createNewAdhocPO = createNewAdhocPO;
    vm.gstSelectionChanged = gstSelectionChanged;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.showConfirm = showConfirm;
    vm.ADHOC_PO_FORM_ITEMS_CSV = S3_URL_CONSTANTS.ADHOC_PO_FORM_ITEMS_CSV
    vm.setDefaultPurchaseOrderType=setDefaultPurchaseOrderType;
    vm.businessOutletChanged=businessOutletChanged;
    vm.cancel = cancel;
    vm.showCSVUpload = showCSVUpload;
    vm.getCSVBase64 = getCSVBase64;
    vm.itemUploadCSV = itemUploadCSV;
    vm.resetValues = resetValues;
    vm.type = '';
    vm.createAdhocPOByCsv = createAdhocPOByCsv;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.shareByWhatsapp = shareByWhatsapp;
    vm.isMobile = $mdMedia('xs');
    vm.showWhatsappConfirmList = showWhatsappConfirmList;
    vm.adhocSupplierList = [];
    vm.redirectAfterShare = redirectAfterShare;
    vm.itemChanged = itemChanged;
    vm.searchForItems = searchForItems;
    vm.itemSearchQuery = {};
    vm.uomChange = uomChange;
    vm.checkGlCode=checkGlCode;
    vm.checkItemPresent=checkItemPresent;
    vm.checkItemGroup= checkItemGroup;
    vm.purchaseOrderTypeChange=purchaseOrderTypeChange;
   /**
     * [resetValues Resetting values when the upload canels or uploads new csv]
     */
    // vm.loadUoms();
   //   vm.loadItemGroups();
    function resetValues(){
      vm.adhocPOItems = [];
      vm.recordError = [];
    }

    /**
     * [itemUploadCSV Uploads csv which contains the items]
     */
    function itemUploadCSV() {
      vm.resetValues();
      var csvFile = vm.files[0]
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function (response) {
          vm.adhocPOItems = response;
          vm.adhocPOFormDTO.adhocPOItems = vm.adhocPOItems;
          vm.type = 'byCSV'
          _.map(vm.adhocPOFormDTO.adhocPOItems, function(each, index){
           vm.updateItemPrice(index);
          });
          vm.updateTotalPrice();
          vm.cancel();
        })
       }
    }
$scope.openDropdown = function(adhocPOItem) {
    adhocPOItem.isEditing = true;
};

$scope.closeDropdown = function(adhocPOItem) {
    adhocPOItem.isEditing = false;
};

    /**
     * [getCSVBase64 Converts the uploaded csv to base64]
     */
    function getCSVBase64() {
      vm.recordError = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        NotificationService.simpleErrorToast({
          title: 'global.pleaseChooseACsvFile',
          message: 'fileIsRequired'
        });
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
        vm.csvUploadData = base64CSV;
        vm.createAdhocPOByCsv(vm.csvUploadData);
      });
    }

    /**
     * [showCSVUpload opens modal to upload CSV file]
     * @param {Object} event
     */
    function showCSVUpload(event){
      $mdDialog.show({
        templateUrl:'app/buyer-modules/adhoc-po-form/templates/buyer.adhoc-po-form.bulk-upload-csv.tmpl.html',
        controller:function(){
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
    }

  
      $scope.showConfirmDialog = function(event) {
        // Create the confirmation dialog
        const confirm = $mdDialog.confirm()
          .title('Confirmation')
          .textContent('Are you sure you want to proceed?')
          .ariaLabel('Confirmation Dialog')
          .targetEvent(event) // Pass the event to position the dialog near the clicked element
          .ok('Yes') // Confirm button
          .cancel('No'); // Cancel button
    
        // Show the dialog and handle the response
        $mdDialog.show(confirm).then(function() {
          // Action when "Yes" is clicked
          vm.createNewAdhocPO()
        }, function() {
          // Action when "No" is clicked (optional)
          console.log("Action canceled.");
        });
      };
    
   
      $scope.showConfirmDialogForItemGroup = function(adhocPOItem,index,event) {
        var itemGroup = _.find(vm.itemGroups, function(itemGroup) {
          return itemGroup.id === adhocPOItem.itemGroup.id;
        });
        // Create the confirmation dialog
        const confirm = $mdDialog.confirm()
          .title('Confirmation')
          .textContent('Are you sure you want to map '+adhocPOItem.itemName+' with item group '+ itemGroup.name+' ?')
          .ariaLabel('Confirmation Dialog')
          .targetEvent(event) // Pass the event to position the dialog near the clicked element
          .ok('Yes') // Confirm button
          .cancel('No'); // Cancel button
    
        // Show the dialog and handle the response
        $mdDialog.show(confirm).then(function() {
          // Action when "Yes" is clicked
          // vm.createNewAdhocPO()
        }, function() {
          // Action when "No" is clicked (optional)
          vm.adhocPOFormDTO.adhocPOItems[index].itemGroup={};
          console.log("Action canceled.");
        });
      };
  
    
  /**
   * Retrieves all business outlets associated with the buyer.
   * By setting the `minimalFields` parameter to `true`, 
   * we limit the response to only include the `id` and `outletName` fields.
   */
  function getAllBuyerBusinessOutlets() {
      if (vm.businessOutlets) return;
      const params = { minimalFields: true };
    BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser(params).
      then(function(response){
        vm.businessOutlets = response;
      },function(error){
        console.error('Error fetching business outlets:', error);
      });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': '',minimalFields: true};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = response.data;
      },function(error){
      });
    }

    function loadItemGroups() {
      if (vm.itemGroups && vm.itemGroups.length > 0) {
        return false;
    }    
      if(vm.adhocPOFormDTO.purchaseOrderType){
        ItemGroupService.getAllItemGroupsByPurchaseOrderType({
          page: 0,
          size: paginationConstants.maxLimit,
          query: '',
          purchaseOrderTypeId: vm.adhocPOFormDTO.purchaseOrderType
        }).then(function(response) {
          vm.itemGroups = response.data;
        }, function(error) {
        });
      }
      else{
        toastr.error('Purchase order type is not selected', 'Purchase order type');
      }
     
    }

    function loadUoms() {
      if(vm.uoms)
        return false;
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.uoms = response.data;
      }, function(error) {
      });
    }

    function init() {
      BuyerAdhocPOFormService.gstConfigDetails().then(function (res) {
        localStorage.setItem("gst-config", JSON.stringify(res[0].configValue));
      });
      vm.adhocPOFormFilter = BuyerAdhocPOFormService.getAdhocPOFormFilter();
      if(vm.adhocPOFormFilter === null) {
        $state.go('buyer.adhoc-po-form');
      }
      else {
        vm.adhocPOFormDTO = {
          adhocPOFormType: vm.adhocPOFormFilter.adhocPOFormType,
          supplierType: 'ADHOC_PO',
          receiptNumber: '',
          receiptDate: vm.todaysDate,
          purchaseDate: vm.todaysDate,
          adhocPOItems: [{}]
        };
        vm.updateTotalPrice();
      }
    }

    /**
     * [createAdhocPOByCsv calls when the upload items is via CSV]
     */
    function createAdhocPOByCsv(csvData){
      vm.adhocPOFormDTO.adhocPOItemsCsv = csvData
      vm.adhocPOCSVItems = angular.copy(vm.adhocPOFormDTO.adhocPOItems);
      vm.adhocPOFormDTO.adhocPOItems = null;
      BuyerAdhocPOFormService.createNewAdhocPOFormCSV(vm.adhocPOFormDTO)
        .then(function(response) {
          vm.adhocPoFormData = {
           'poNumber':response.data.poNumber,
           'deliveryDate':response.data.deliveryDate
         };
          vm.type = '';
          if(vm.adhocPOFormDTO.adhocPOFormType === "ADHOC_PO_OFF_BOARDED_SUPPLIER"){
            vm.adhocSupplierList.push({'supplier': vm.adhocPOFormDTO.supplier.companyName, 'poNumber' : response.data.poNumber});
            // if(vm.haveAccessForWhatsappPo){
            //  // vm.showWhatsappConfirmList();
            // }
            // else{
              $state.go('buyer.receiving-orders.details',{
                'poNumber':vm.adhocPoFormData.poNumber,
                'deliveryDate':vm.adhocPoFormData.deliveryDate
              }, {
                reload: false
              });
            // }
          }
          if(vm.adhocPOFormDTO.adhocPOFormType === "ADHOC_PO_ON_BOARDED_SUPPLIER"){
            $state.go('buyer.receiving-orders.details',{
              'poNumber':response.data.poNumber,
              'deliveryDate':response.data.deliveryDate
            }, {
              reload: false
            });
          }
          NotificationService.success({
            title: 'global.recoveryExternalPoForm',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
          BuyerAdhocPOFormService.clearAdhocPOFormFilter();
        }, function(error) {
          vm.recordError = error.data;
          NotificationService.error({
            title: 'global.createRecoveryExternalPOForm',
            error: error
          });
        });
    }

    /**
     * [createAdhocPOByForm calls when the upload items is via Manual]
     */
    function createAdhocPOByForm(){ 

      BuyerAdhocPOFormService.createNewAdhocPOForm(vm.adhocPOFormDTO)
        .then(function(response) {
          vm.adhocSupplierList = [];
          vm.poNumber = response.data.poNumber;
          vm.adhocPoFormData = {
           'poNumber':response.data.poNumber,
           'deliveryDate':response.data.deliveryDate
         };
          if(vm.adhocPOFormDTO.adhocPOFormType === "ADHOC_PO_OFF_BOARDED_SUPPLIER"){
            vm.adhocSupplierList.push({'supplier': vm.adhocPOFormDTO.supplier.companyName, 'poNumber' : vm.poNumber});
              $state.go('buyer.receiving-orders.details',{
                'poNumber':vm.adhocPoFormData.poNumber,
                'deliveryDate':vm.adhocPoFormData.deliveryDate
              }, {
                reload: false
              });

          }
          if(vm.adhocPOFormDTO.adhocPOFormType === "ADHOC_PO_ON_BOARDED_SUPPLIER"){
            $state.go('buyer.receiving-orders.details',{
              'poNumber':response.data.poNumber,
              'deliveryDate':response.data.deliveryDate
            }, {
              reload: false
            });
          }
          NotificationService.success({
            title: 'global.recoveryExternalPoForm',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
          BuyerAdhocPOFormService.clearAdhocPOFormFilter();
        }, function(error) {
          vm.translatedError = error.data;
          NotificationService.error({
            title: 'global.createRecoveryExternalPOForm',
            error: error
          });
        });
    }

    /**
     * [createNewAdhocPO This function decides whether the creation is done by CSV upload or manual]
     */
    vm.GlMappingError=false;
    function createNewAdhocPO() {
      vm.recordError = null;
      if(vm.type == 'byCSV'){
        vm.getCSVBase64();
      }else{
          
          !vm.GlMappingError?createAdhocPOByForm():'';
      }
    }
    
    vm.errorGlcode=[];
    function checkGlCode(adhocPOItem,index)
    {
     vm.checkItemPresent(adhocPOItem,index)
     if(vm.haveAccessForGLModule)
     {
      var SelectedpurchaseOrderTypes = _.find(vm.purchaseOrderTypes, function(poType) {
        return poType.id === vm.adhocPOFormDTO.purchaseOrderType;
      });
      // var itemGroup = _.find(vm.itemGroups, function(itemGroup) {
      //   return itemGroup.id === adhocPOItem.itemGroup.id;
      // });
      var itemGroup =adhocPOItem.itemGroup;
      var purchaseOrderTypeId=vm.adhocPOFormDTO.purchaseOrderType;
      if(purchaseOrderTypeId==undefined)
      {
        toastr.error('Purchase order type is not selected', 'Purchase order type', NotificationService.getToastrOptions());
       
      }
      var glMappingObject={
        "itemGroupId": itemGroup.id,
        "purchaseOrderTypeId":purchaseOrderTypeId
    };
    GlMappingService.getAllGlMappings({},glMappingObject).then(function(response){
      vm.totalItems = response.headers('X-Total-Count');
      vm.glMappings = response.data;
      adhocPOItem.glCodeMappings=vm.glMappings;
      vm.adhocPOFormDTO.adhocPOItems[index].glCodeMappings=vm.glMappings[0];
      
      if(vm.glMappings.length>0){
       vm.errorGlcode=UtilService.removeItemOnce(vm.errorGlcode,index);
      }
      else{
        vm.errorGlcode= UtilService.addItemOnce(vm.errorGlcode,index);
        toastr.error('Purchase order type '+SelectedpurchaseOrderTypes.name+' is not mapped with item group '+itemGroup.name+'', 'GL Mapping Listing', NotificationService.getToastrOptions());
       
      }
    },function(error){
      NotificationService.error({
        title: 'GL Mapping Listing',
        error: error
      });
      vm.errorGlcode= UtilService.addItemOnce(vm.errorGlcode,index);
    });
     }
    
    }
    function notify(error){
      NotificationService.error({
        title: 'global.createRecoveryExternalPOForm',
        error: error
      });
    }
    function checkIsTaxActive() {
      vm.isTaxActive = false;
      if(vm.supplierList && vm.adhocPOFormDTO.supplier.id){
        vm.selectedSupplier = _.find(vm.supplierList, function(supplier) { return supplier.id === vm.adhocPOFormDTO.supplier.id; });
        vm.selectedSupplier.tax === "Active" ? vm.isTaxActive = true : vm.isTaxActive = false;
      }
    }

    function addNewAdhocInvoiceRow() {
      vm.adhocPOFormDTO.adhocPOItems.push({});
      vm.updateTotalPrice();
      vm.gstSelectionChanged()
    }

    function deleteAdhocInvoiceRow(index) {
      vm.adhocPOFormDTO.adhocPOItems.splice(index, 1);
      UtilService.removeItemOnce(vm.errorGlcode,index)
      vm.updateTotalPrice();
      vm.gstSelectionChanged();
    }

    function freeItemCheckBoxChanged(index, isFreeItem) {
      if(isFreeItem === true){
        vm.adhocPOFormDTO.adhocPOItems[index].price = 0;
      }
      else if(isFreeItem === false && vm.adhocPOFormDTO.adhocPOFormType === 'ADHOC_PO_ON_BOARDED_SUPPLIER'){
        vm.adhocPOFormDTO.adhocPOItems[index].price = vm.adhocPOFormDTO.adhocPOItems[index].oldPrice;
      }
      vm.updateItemPrice(index);
    }

    function updateItemPrice(index) {
      vm.adhocPOFormDTO.adhocPOItems[index].totalPrice = vm.adhocPOFormDTO.adhocPOItems[index].price * vm.adhocPOFormDTO.adhocPOItems[index].quantity;
      vm.updateTotalPrice();
      if(!vm.adhocPOFormDTO.adhocPOItems[index].uom){
        vm.createAdhocPOForm['quantity_' + index].$setValidity("max-precision", true);
      }
      if (vm.adhocPOFormDTO.adhocPOItems[index].quantity != undefined && !vm.adhocPOFormDTO.adhocPOItems[index].uom.weightRequired) {
        var number = Number(vm.adhocPOFormDTO.adhocPOItems[index].quantity);
        if (number === Math.floor(number)) {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", true);
        } else {
          vm.createAdhocPOForm['quantity_' + index].$setTouched();
          vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", false);
        }
      } else {
        vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", true);
      } 
      vm.gstSelectionChanged()    
    }

    function updateTotalPrice() {
      vm.adhocPOFormDTO.totalAmount = _.sumBy(vm.adhocPOFormDTO.adhocPOItems, 'totalPrice');
      vm.calculateGST();
    }

    function calculateGST() {
        var gstValue = JSON.parse(localStorage.getItem("gst-config")) / 100;
        if ( vm.adhocPOFormDTO.adhocPOFormType === 'ADHOC_PO_OFF_BOARDED_SUPPLIER' ){
                vm.adhocPOFormDTO.gst = vm.adhocPOFormDTO.totalAmount - (vm.adhocPOFormDTO.totalAmount / (1 + gstValue));
        }
        else if(vm.isTaxActive){
                vm.adhocPOFormDTO.gst = vm.adhocPOFormDTO.totalAmount * vm.selectedSupplier.gst / 100;
        }
    }

    function loadPurchaseOrderTypes() {
      if(vm.purchaseOrderTypes===undefined){
        vm.purchaseOrderTypes = [];
        PurchaseOrderTypeService.getAllPurchaseOrderTypes({
          isPageable: false
        }).then(function(response) {
          vm.purchaseOrderTypes = response.data;
        }, function(error) {
        });
      }
       
     
     
    }
 function businessOutletChanged() 
 {vm.setDefaultPurchaseOrderType();}

 function setDefaultPurchaseOrderType() 
 {
    vm.loadPurchaseOrderTypes()
    if(vm.businessOutlets && vm.haveAccessForGLModule)
    {
      var selectedOutlet = _.find(vm.businessOutlets, ['id', vm.adhocPOFormDTO.buyerBusiness.id]);
      vm.adhocPOFormDTO.purchaseOrderType = selectedOutlet.defaultPurchaseOrderType ? selectedOutlet.defaultPurchaseOrderType.id : undefined;
      vm.defaultPurchaseOrderTypeLocked= selectedOutlet.defaultPurchaseOrderTypeLocked; 
      if(vm.adhocPOFormDTO.purchaseOrderType ){
        vm.purchaseOrderTypeChange();
      }
    }
  }
    function gstSelectionChanged(){
      if(vm.adhocPOFormDTO.gstEnabled===false){
        vm.adhocPOFormDTO.gst = 0.00;
      }
      else{
        vm.calculateGST();
      }
    }

    function showConfirm(ev) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.CANCEL_ADHOC_MESSAGE'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.yes'))
      .cancel($filter('translate')('global.buttons.no'));

      $mdDialog.show(confirm).then(function() {
        $state.go('buyer.dashboard');
      }, function() {
      vm.redirectAfterShare();
      });
    }
 

    /**
    * [redirectAfterShare redirect to grn page after sharing PO via whatsapp]
    * @return {[type]} [description]
    */
    function redirectAfterShare(){
      $state.go('buyer.receiving-orders.details',{
        'poNumber':vm.adhocPoFormData.poNumber,
        'deliveryDate':vm.adhocPoFormData.deliveryDate
      }, {
        reload: false
      });
      $mdDialog.cancel();
    }

    //share PO details via whatsapp
    function shareByWhatsapp(purchaseOrder, poNumber){
      var itemNames = _.map(vm.adhocPOFormDTO.adhocPOItems? vm.adhocPOFormDTO.adhocPOItems: vm.adhocPOCSVItems,function(adhocPOItems){
        if(adhocPOItems.uom.id){  // for adhoc po generation by form --- csv does not have uom object inside adhocPODTO
          vm.uomObject= _.find(vm.uoms, ['id', adhocPOItems.uom.id]);
        }
        return adhocPOItems.quantity+ ' X ('+(vm.uomObject? vm.uomObject.name : adhocPOItems.uom)+ ') '+ adhocPOItems.itemName;
      });

      var items =_.join(itemNames,'%0D%0A');
      var businessOutletObject =_.find(vm.businessOutlets, ['id', vm.adhocPOFormDTO.buyerBusiness.id]);
      vm.purchaseDate =  moment(vm.adhocPOFormDTO.purchaseDate).format("DD-MM-YYYY");
      vm.receiptDate =  moment(vm.adhocPOFormDTO.receiptDate).format("DD-MM-YYYY");

      if(vm.isMobile){
        var whatsappShareUrlMobile = "whatsapp://send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
        businessOutletObject.businessOutletName+
        "%0D%0ADelivery Address: " +
        "%0D%0A"+(businessOutletObject.businessOutletName? businessOutletObject.businessOutletName: "NA") +
        (businessOutletObject.shippingAddress? (", " + businessOutletObject.shippingAddress) : "")+
        "%0D%0A"+(businessOutletObject.city? businessOutletObject.city: "")+
        (businessOutletObject.postalCode? (", "+ businessOutletObject.postalCode): "")+
        "%0D%0APO Date:"+vm.purchaseDate+
        "%0D%0APO Number:"+poNumber+
        "%0D%0ADelivery Date: "+ vm.receiptDate+
        "%0D%0A%0D%0AProduct:%0D%0A"+ items+
        "%0D%0A%0D%0A"+purchaseOrder;
        var mobileLink = document.createElement("a");
        document.body.appendChild(mobileLink);
        mobileLink.download = "Whatsapp_PO";
        mobileLink.href = whatsappShareUrlMobile;
        mobileLink.click();
      }
      else{
        var whatsappShareUrlWeb = "https://web.whatsapp.com/send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
        businessOutletObject.businessOutletName+
        "%0D%0ADelivery Address: " +
        "%0D%0A"+(businessOutletObject.businessOutletName? businessOutletObject.businessOutletName: "NA") +
        (businessOutletObject.shippingAddress?(", "+ businessOutletObject.shippingAddress) : "")+
        "%0D%0A"+(businessOutletObject.city? businessOutletObject.city: "")+
        (businessOutletObject.postalCode? (", "+ businessOutletObject.postalCode): "")+
        "%0D%0APO Date:"+vm.purchaseDate+
        "%0D%0APO Number:"+poNumber+
        "%0D%0ADelivery Date: "+ vm.receiptDate+
        "%0D%0A%0D%0AProduct:%0D%0A"+ items+
        "%0D%0A%0D%0A"+purchaseOrder;
        window.open(whatsappShareUrlWeb);
      }
    }

    /**
    * [showWhatsappConfirmList modal for listing out suppliers to whom PO details is to be shared]
    * @param  {[type]} event [description]
    * @return {[type]}       [description]
    */
    function showWhatsappConfirmList(event) {
      // update card Id
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/whatsapp-pdf-share.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }


    //get the pdf for individual purchase order details
    function getIndividualPurchaseOrderPDF(poNumber) {
      PDFExportService.getPurchaseOrderPDF(poNumber).then(function(response){
        vm.shareByWhatsapp(response.data.message, poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Purchase Order PDF Export',
          error: error
        });
      });
    }

    /**
     * [Get all supplier items which are mapped to a buyer business outlets. Search query as parameter.]
     * @param {String} queryType
     * @param {String} queryText
     */
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if(!vm.adhocPOFormDTO.supplier || !vm.adhocPOFormDTO.buyerBusiness){
        !vm.adhocPOFormDTO.supplier ? (
          NotificationService.simpleErrorToast({
          title: 'global.createRecoveryExternalPOForm',
          message: 'global.pleaseSelectASupplierToStart'
        })
        ):(
          NotificationService.simpleErrorToast({
            title: 'global.createRecoveryExternalPOForm',
            message: 'global.pleaseSelectABusinessOutletToStart'
          })
        );
        vm.itemSearchQuery.queryText = '';
        return false;
      }
      if(!queryText) {
        return false;
      }
      else {
        vm.itemSearchQuery = {
          queryField : queryType,
          queryText : queryText,
          supplierId : vm.adhocPOFormDTO.supplier.id,
          businessIdList: [vm.adhocPOFormDTO.buyerBusiness.id],
          itemType : 'BUYER',
          buyerIdList : null
        };
        BuyerItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          var orderByitemName= $filter('orderBy')(response.data, 'itemName');
          var uniqueitemName= $filter('unique')(orderByitemName, 'itemCode');
          deferred.resolve(
            uniqueitemName
            );
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
     * [itemChanged Sets the item objects values to the adhoc item]
     * @param {Object} adhocPOItem
     * @param {Integer} index
     */
    function itemChanged(adhocPOItem, index) {
      
      var temp = angular.copy(adhocPOItem);
      adhocPOItem.itemName = temp.selectedItem.itemName;
      adhocPOItem.uom = temp.selectedItem.uom;
      adhocPOItem.itemGroup =  temp.selectedItem.itemGroup;
      adhocPOItem.itemGroupList=[adhocPOItem.itemGroup] ;
      adhocPOItem.price = temp.selectedItem.price.toFixed(4);
      adhocPOItem.oldPrice = temp.selectedItem.price;
      if(temp.selectedItem.uom.weightRequired){
        adhocPOItem.maxPrecision = 3;
      }
      else {
        adhocPOItem.maxPrecision = 2;
      }
      vm.checkItemGroup(adhocPOItem, index);
     // vm.checkGlCode(temp.selectedItem,index);
    }

     /**
     * [check quantity decimal places, if wieght-required then 3 decimal places else 2 decimal places ]
     * @param {Object} adhocPOItem
     * @param {Integer} id
     * @param {Integer} index
     */
    function uomChange(adhocPOItem, id, index){
      vm.checkItemPresent(adhocPOItem, index)
      var UOM =  _.find( vm.uoms, function(uom) { return uom.id == id});
      if(!UOM.weightRequired){
        adhocPOItem.maxPrecision = 2;
        adhocPOItem.uom.weightRequired = false;
        var len = adhocPOItem.quantity.toString().split(".")[1].length || 0;
        if(len >= 3) {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("max-precision", false);
        }
        else {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("max-precision", true);
        }
      }
      else {
        adhocPOItem.maxPrecision = 3;
        adhocPOItem.uom.weightRequired = true;
        var len = adhocPOItem.quantity.toString().split('.')[1].length || 0;
        if(len > 3) {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("max-precision", false);
        }
        else {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("max-precision", true);
        }
      }
      if (vm.adhocPOFormDTO.adhocPOItems[index].quantity != undefined && !adhocPOItem.uom.weightRequired) {
        var number = Number(vm.adhocPOFormDTO.adhocPOItems[index].quantity);
        if (number === Math.floor(number)) {
          vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", true);
        } else {
          vm.createAdhocPOForm['quantity_' + index].$setTouched();
          vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", false);
        }
      } else {
        vm.createAdhocPOForm['quantity_' + index].$setValidity("moq", true);
      }
    }

    function checkItemPresent(adhocPOItem, index) {
     
      
      // Check if all required properties are present and have values
      if (adhocPOItem.isNewItem && adhocPOItem.itemName && adhocPOItem.itemGroup && adhocPOItem.uom && vm.selectedSupplier) {
          console.log("All fields are available. Checking item existence...");
  
          // Call the service method to check if the item exists
          BuyerItemService.checkItemExist(
              adhocPOItem.itemName,
              adhocPOItem.itemGroup.id,
              adhocPOItem.uom.id,
              vm.selectedSupplier.id
          ).then(function(exists) {
              if (exists) {
                toastr.error(adhocPOItem.itemName, "Item already exists in the system.");
                adhocPOItem.itemName="";
                  // Your logic here if the item exists
              } else {
                  console.log("Item does not exist. Proceeding with further logic.");
                  // Your logic here if the item does not exist
              }
          }).catch(function(error) {
              console.error("Error checking item existence:", error);
          });
      } else {
          console.log("Required fields are missing. Code will not execute.");
      }
  }
  function checkItemGroup(adhocPOItem, index) {
    if (_.isEmpty(adhocPOItem)) {
      vm.adhocPOFormDTO.adhocPOItems=[];
      vm.adhocPOFormDTO.adhocPOItems.push({});
    
    }
    else{
   // Check if adhocPOItem.itemGroup.id exists in vm.itemGroups
   const itemGroupExists = _.some(vm.itemGroups, { id: _.get(adhocPOItem, 'itemGroup.id') });

   if (itemGroupExists) {
       
       // Perform desired actions when itemGroup is found
   } else {
     var SelectedpurchaseOrderTypes = _.find(vm.purchaseOrderTypes, function(poType) {
       return poType.id === vm.adhocPOFormDTO.purchaseOrderType;
     });
   
     toastr.error('Purchase order type '+SelectedpurchaseOrderTypes.name+' is not mapped with item group '+adhocPOItem.itemGroup.name+'', 'GL Mapping Listing', NotificationService.getToastrOptions());
     vm.adhocPOFormDTO.adhocPOItems[index].itemGroup={}
     
       // Perform actions when itemGroup is not found
   }
    }

 
}


function purchaseOrderTypeChange()
{

  vm.itemGroups=null;
  vm.loadItemGroups();
  checkItemGroup();
}

  



  }
})();
